import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ContactStatus,
  formatPhoneNumber,
} from "../../../../common/helper/Common.js";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import New from "../../../../common/img/New.png";
import NewGlobalTable from "../../../../common/components/custom-ui/NewGlobalTable.jsx";
import { truncateText } from "../../../../common/helper/BasicFn.js";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice.js";
import { isPermission } from "../../../../common/helper/PermissionManager.jsx";

const ContactListing = ({ setTabTbldata, is_user, accountId, siteId }) => {
  const navigate = useNavigate();
  const filterData = useSelector((state) => state.accountlistfilter.filterData);
  const [showLoading, setShowLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [contactList, setSiteContactList] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
  const subAdminPermission = [
    "site-details",
    "contact-tab",
    "equipment-tab",
    "training-tab",
    "inperson-tab",
    "notes-tab",
    "documents-tab",
  ];

  const BreadCrumbsObject = {
    title: "",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
    // type: "site",
    // tab: "CONTACT_LISTING",
  };
  const handleNewDispatch = (accountId) => {
    BreadCrumbsObject.title = "New Contact Form";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/contacts/new/${accountId}`,
      })
    );
  };
  const handleDispatched = (accountId, contact_id, name) => {
    BreadCrumbsObject.title = name + " Details";
    // BreadCrumbsObject.tab = "Details";
    // BreadCrumbsObject.type = "contact";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/${accountId}/contact-details/${contact_id}/Details`,
      })
    );
  };
  const RenderContactName = (data) => {
    return (
      <>
        <span
          className={
            Number(isPermission({ type: "link", name: "contactName" })) === 1
              ? "link"
              : ""
          }
          onClick={() => {
            if (
              Number(isPermission({ type: "link", name: "contactName" })) === 1
            ) {
              handleDispatched(accountId, data.contact_id, data?.contact_name);
              navigate(
                `/account/${accountId}/contact-details/${data.contact_id}/Details`,
                {
                  state: {
                    // siteTitle: "Contact : " + data?.contact_name,
                    editUrl: `/account/contact-details-edit/${data.contact_id}`,
                    deleteUrl: `/account/contact-details-edit/${data.contact_id}`,
                    type: "Contacts",
                  },
                }
              );
            }
          }}
        >
          {truncateText(data?.contact_name, 20)}
        </span>
      </>
    );
  };

  const RenderUser = (data) => {
    return (
      <>
        {data?.user ? (
          <CheckIcon color={"success"} />
        ) : (
          <CloseIcon color="error" />
        )}
      </>
    );
  };

  const RenderPhone = (data) => {
    return (
      <>
        {data?.phone?.[0].phone != "" && data?.phone?.[0].phone != "-" && (
          <a
            className="link"
            style={{ textDecoration: "none" }}
            href={"tel:" + data?.phone?.[0].ext + data?.phone?.[0]?.phone}
          >
            {data?.phone?.[0].phone
              ? formatPhoneNumber(data?.phone?.[0].phone)
              : ""}
            {data?.phone?.[0].ext != "" ? "x" + data?.phone?.[0].ext : ""}
          </a>
        )}
      </>
    );
  };

  const RenderEmail = (data) => {
    return (
      <>
        {data?.email.length > 0 && (
          <a
            className="link"
            style={{ textDecoration: "none" }}
            href={"mailto:" + data?.email?.[0].email}
          >
            {data?.email?.[0].email}
          </a>
        )}
      </>
    );
  };

  const headerColumns = [
    {
      Header: "Contact",
      accessor: "contact_name",
      Cell: ({ row }) => <div> {RenderContactName(row.original)}</div>,
      width: "15%",
    },
    {
      Header: "User",
      accessor: "user",
      width: "5%",
      Cell: ({ row }) => <div>{RenderUser(row.original)}</div>,
      disableSortBy: true,
    },
    {
      Header: "Phone",
      accessor: "phone",
      width: "15%",
      Cell: ({ row }) => <div>{RenderPhone(row?.original)}</div>,
      disableSortBy: true,
    },
    {
      Header: "Email",
      accessor: "email",
      width: "20%",
      Cell: ({ row }) => <div>{RenderEmail(row.original)}</div>,
    },
    {
      Header: "Positions",
      accessor: "account_roles",
      width: "8%",
      Cell: ({ row }) => <div>{row.original?.account_roles.join(", ")} </div>,
    },
    {
      Header: "Sites",
      accessor: "main_site",
      width: "15%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "8%",
      Cell: ({ row }) => (
        <p className={row.original?.status == 1 ? "" : "text-danger"}>
          {ContactStatus[row.original?.status]}
        </p>
      ),
    },
  ];

  return (
    <>
      <Box className="d-flex justify-content-between align-items-center py-2">
        <h3 className="heading">{/* Site Contacts */}</h3>
        {Number(isPermission({ type: "btn", name: "newContact" })) === 1 ? (
          <>
            <Link
              style={{ textDecoration: "none" }}
              // onClick={handleDispatch(accountId)}
              onClick={() => {
                handleNewDispatch(accountId);
              }}
              to={"/account/contacts/new/" + accountId}
            >
              <img src={New} />
              <span style={{ color: "#0C71C3" }}> New</span>
            </Link>
          </>
        ) : (
          ""
        )}
      </Box>
      <NewGlobalTable
        method="GET"
        isCache={true}
        // keyAPiName="contact_list"
        apiEndPoint={`site/site-contacts-list/${siteId}`}
        headerColumns={headerColumns}
      />
    </>
  );
};

export default ContactListing;
