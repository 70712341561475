import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, FormControlLabel, Switch } from "@mui/material";
import SubHeadingOther from "../../../common/components/SubHeadingOther";
import "../CSS/pop.css";
import SubHeading from "../../accounts/components/header/SubHeading";
// import styles from "../css/NewAccount.module.css";
import styles from "../../accounts/css/NewAccount.module.css";

import {
  Form,
  Button as BButton,
  Button as BsButton,
  InputGroup,
  Spinner,
} from "react-bootstrap";
// import MessageHandler from "../../../common/components/MessageHandler";
// import Select from "react-select";
import DataGrid, {
  Scrolling,
  Paging,
  Column,
} from "devextreme-react/data-grid";
// import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import dayjs from "dayjs";
import CustomToggleButton from "../../../common/components/toggleSwitch/CustomToggleButton";
import Contacts from "../../../common/img/Contacts.svg";
import Locations from "../../../common/img/Locations.svg";
import AEDCabinet from "../../../common/img/AEDCabinet.svg";
import Adult from "../../../common/img/Adult.svg";
import pediatricPad from "../../../common/img/pediatricPad.svg";
import Battery from "../../../common/img/Battery.svg";
import RMSBattery from "../../../common/img/RMSBattery.svg";
import Cancel from "../../../common/img/Cancel.svg";
import Check from "../../../common/img/Check.svg";
import Edit from "../../../common/img/Edit.png";
import Equipment from "../../../common/img/Equipment.svg";
import Accessories from "../../../common/img/Accessories.svg";
import MoneyBag from "../../../common/img/MoneyBag.svg";
import Courses from "../../../common/img/Courses.svg";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services/index";
import PopEquipment from "../../PopManagement/Pages/PopEquipment";
import PopAccessories from "../../PopManagement/Pages/PopAcessories";
import PopCourses from "../../PopManagement/Pages/PopCourses";
// import AddSitespagemodal from "./AddSitesPage";
import AddSitesmodal from "../../PopManagement/Pages/AddSitesPage";
// import AddContactsmodal from "./AddContactspage";
// import InpersonContactModel from "../../../components/modals/InpersonContactModel/InpersonContactModel";
import {
  ContactList,
  FetchAccountDetails,
  GetCertAgencyList,
  PriceFormat,
} from "../../../common/helper/BasicFn";
import {
  Debounce,
  FormatDate,
  RemoveDollarSign,
  addDollarSign,
  prepareOptions,
} from "../../../common/helper/Common";
import { toast } from "react-toastify";
import AddContactsmodalNew from "../Pages/AddContactsmodalNew";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../src/global.css";
import CommonDatePicker from "../../../common/components/DatePickers/CommonDatePicker";
import { resetAllPops } from "../../../store/slices/EquipmentSlice";
import { addToHistoryStack, removeBreadcrumb } from "../../../store/slices/breadcrumbsSlice";
import { MultiSelect } from "react-multi-select-component";

const overlay = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  zIndex: 2,
};

const NewPop = () => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [validatedContract, setValidatedContract] = useState(false);
  const { accountId } = useParams();
  const [ProductModalData, setProductModalData] = useState([]);
  const dispatch = useDispatch();

  const EquipEquipmentList = useSelector(
    (state) => state.equipment.equipmentList
  );
  const ContractEquipmentList = useSelector(
    (state) => state.equipment.ContractEquipmentList
  );
  // ContractEquipmentList
  const EquipAccessoriesList = useSelector(
    (state) => state.equipment.AssesoriesList
  );
  const ContractAccessoriesList = useSelector(
    (state) => state.equipment.ContractAssesoriesList
  );
  const PopCourseListData = useSelector(
    (state) => state.equipment.POPCourseList
  );

  const [planTypes, setPlanTypes] = useState([]);
  const [selectedPlanType, setSelectedPlanType] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [accessoriesModal, setAccessoriesModal] = useState(false);
  const [type, setType] = useState(null);
  const [edit, setEdit] = useState("");
  const [courseModal, setCourseModal] = useState(false);
  const [addSitesModal, setAddSitesModal] = useState(false);
  const [addContactsModal, setAddContactsModal] = useState(false);
  const [isEquipmentSelected, setIsEquipmentSelected] = useState(false);
  const [getPlanType, setGetPlanType] = useState([]);
  const [cerifyAgencyList, setCertifyAgency] = useState([]);
  const [ContractPricingData, setContractPricingData] = useState([]);
  const [purchaseProEquip, setPurchaseProEquip] = useState([]);
  const [purchaseProAssesory, setPurchaseProAssesory] = useState([]);
  const [ContractPricEquip, setContractPricEquip] = useState([]);
  const [ContractPricAssesory, setContractPricAssesory] = useState([]);
  const [showSitesError, setShowSitesError] = useState(false);
  const [showContactsError, setShowContactsError] = useState(false);
  const [ShowGraceError, setShowGraceError] = useState(false);
  const [ShowPopError, setShowPopError] = useState(false);
  const [formData, setFormData] = useState({
    contractNumber: "",
    reqNumber: "",
    orderNumber: "",
    modificationNumber: "",
    contractStart: null,
    contractYear: "",
    OfYear: "",
    gracePeriod: "45",
    popType: "",
    qbInvoiceNumber: "",
    invoicePaid: 0,
    invoicingInstructions: "",
    contacts: "",
    sites: "",
    planType: "",
    rental: false,
    visits: "Annual",
    rms: true,
    accessoriesIncluded: [
      "MoneyBag",
      "Battery",
      "Battery",
      "Adult",
      "Adult",
      "AEDCabinet",
      "RMSBattery",
    ],
    contractCLINS: "",
    RMSMonthlyPrice: "",
    Price: 0,
  });
  const [loading, setLoading] = useState(false);
  const textAreaRef = useRef(null);
  // const saveForm = async () => {
  //   if (isEquipmentSelected) {
  //     if (!selectedPlanType) {
  //       setValidated(true);
  //       toast.error("Please Fill All The Required Details");
  //       return;
  //     }
  //   }
  //   // !formData.contractType ||
  //   if (
  //     !formData.popType
  //     // !formData.contacts ||
  //     // !formData.sites ||
  //     // !(isEquipmentSelected && !selectedPlanType)
  //   ) {
  //     setValidated(true);
  //     toast.error("Please Fill All The Required Details");
  //     return;
  //   }
  //   const siteIds = selectedSites.map((it) => it.account_site_info_id);
  //   if (!siteIds) {
  //     toast.error("Please Select Site");
  //     return "";
  //   }
  //   // const checkSelectedContact =
  //   //   selectedContact?.contract_officer?.length > 0 ||
  //   //   selectedContact?.contracting_officer_rep?.length > 0 ||
  //   //   selectedContact?.other_reps?.length > 0;
  //   // if (!checkSelectedContact) {
  //   //   toast.error("Please Select Contact");
  //   //   return "";
  //   // }
  //   setLoading(true);
  //   const EquipmentIds = EquipEquipmentList.map(
  //     (it) => it.equipment_id
  //   ).toString();
  //   const EquipmentAssesIds = EquipAccessoriesList.map(
  //     (it) => it.accessories_id
  //   ).toString();
  //   const COntractAssesIds = ContractAccessoriesList.map(
  //     (it) => it.accessories_id
  //   ).toString();
  //   const ContractEquipmentIds = ContractEquipmentList.map(
  //     (it) => it.equipment_id
  //   ).toString();
  //   const CourseIds = PopCourseListData.map((it) => it.id).toString();

  //   const payLoad = {
  //     account_id: accountId,
  //     contract: formData.contractNumber,
  //     req: formData.reqNumber,
  //     order: formData.orderNumber,
  //     modification: formData.modificationNumber,
  //     of_year: formData.numberOfYears,
  //     contract_start: formData.contractStart,
  //     contract_year: formData.contractYear,
  //     pop_type: formData.popType,
  //     grace_period: formData.gracePeriod,
  //     qb_invoice: formData.qbInvoiceNumber,
  //     invoice_paid: formData?.invoicePaid || 0,
  //     invoicing_instructions: formData.invoicingInstructions,
  //     contact: selectedContact,
  //     sites: siteIds.toString(),
  //     plan_id: selectedPlanType,
  //     active: 0,

  //   };

  //   let result = await CallPOSTAPI("pop/add-pop", payLoad);
  //   if (result.data.status) {
  //     handleRemoveDispatch(accountId)
  //     navigate('/account-details/' + accountId)
  //     toast.success("Pop Added Successfully");
  //   } else {
  //     toast.error(result.data.msg);
  //   }
  //   setFormData({ type: result?.data?.status, msg: result?.data?.data });
  //   setLoading(false);
  // };
  // save form
  //   const saveForm = async() => {
  //   setFormData({ type: result?.data?.status, msg: result?.data?.data });
  // };
  
  const saveForm = async () => {
    if (isEquipmentSelected) {
      if (!selectedPlanType) {
        setValidated(true);
        toast.error("Please Fill All The Required Details");
        return;
      }
    }
    if (!formData.popType) {
      setValidated(true);
      toast.error("Please Fill All The Required Details");
      return;
    }

    const siteIds = selectedSites.map((it) => it.value).join(",");
    if (!siteIds) {
      toast.error("Please Select Site");
      return "";
    }
    console.log("siteIds:", siteIds);
  
    setLoading(true);
  
    const payLoad = {
      account_id: accountId,
      contract: formData.contractNumber,
      req: formData.reqNumber,
      order: formData.orderNumber,
      modification: formData.modificationNumber,
      of_year: formData.numberOfYears,
      contract_start: formData.contractStart,
      contract_year: formData.contractYear,
      pop_type: formData.popType,
      grace_period: formData.gracePeriod,
      qb_invoice: formData.qbInvoiceNumber,
      invoice_paid: formData?.invoicePaid || 0,
      invoicing_instructions: formData.invoicingInstructions,
      contact: {
        contract_officer: selectedContact.contract_officer.map(contact => ({
          contact_id: contact.value,
          contact_name: contact.label
        })),
        contracting_officer_rep: selectedContact.contracting_officer_rep.map(contact => ({
          contact_id: contact.value,
          contact_name: contact.label
        })),
        other_reps: selectedContact.other_reps.map(contact => ({
          contact_id: contact.value,
          contact_name: contact.label
        })),
      },
      sites: siteIds,
      plan_id: selectedPlanType,
      active: 0,
    };
  
    let result = await CallPOSTAPI("pop/add-pop", payLoad);
    if (result.data.status) {
      handleRemoveDispatch(accountId);
      navigate('/account-details/' + accountId);
      toast.success("Pop Added Successfully");
    } else {
      toast.error(result.data.msg);
    }
    setFormData({ type: result?.data?.status, msg: result?.data?.data });
    setLoading(false);
  };

  const breadcrumbsFromStore = useSelector((state) => state.BreadCrumbs.breadcrumbs);
  const handleRemoveDispatch = (accountId, is_active) => {
    if (is_active === 0) {
      const editPageUrl = '/account/new-pop/' + accountId
      dispatch(removeBreadcrumb(editPageUrl));
    } else {
      const lastBreadcrumb = breadcrumbsFromStore[breadcrumbsFromStore.length - 1]
      dispatch(addToHistoryStack(lastBreadcrumb))
      const editPageUrl = '/account/new-pop/' + accountId
      dispatch(removeBreadcrumb(editPageUrl));
    }
  }

  // const debouncedPriceFunction = Debounce(calculateYearlyPrice, 1000);

  const handleSubmit = (event) => {
    event.preventDefault();

    saveForm(accountId);

    let formIsValid = true;

    // Check contractStart
    if (!formData.contractStart) {
      setValidatedContract(true);
      formIsValid = false;
    } else {
      setValidatedContract(false);
    }

    // Check gracePeriod
    if (formData.gracePeriod === "") {
      setShowGraceError(true);
      formIsValid = false;
    } else {
      setShowGraceError(false);
    }

    // Check popType
    if (formData.popType === "") {
      setShowPopError(true);
      formIsValid = false;
    } else {
      setShowPopError(false);
    }

    // Check selected contacts
    const checkSelectedContact =
      selectedContact?.contract_officer?.length > 0 ||
      selectedContact?.contracting_officer_rep?.length > 0 ||
      selectedContact?.other_reps?.length > 0;

    // if (!checkSelectedContact) {
    //   setShowContactsError(true);
    //   formIsValid = false;
    // } else {
    //   setShowContactsError(false);
    // }

    // Check selected sites
    if (selectedSites.length === 0) {
      setShowSitesError(true);
      formIsValid = false;
    } else {
      setShowSitesError(false);
    }

    // Check other form fields

    // If form is not valid, stop submission
    if (!formIsValid) {
      return;
    }

    // If everything is valid, submit the form
    // saveForm();
  };

  const calendarIcon = () => {
    return (
      <img src="/calendar.svg" alt="calendar" style={{ marginLeft: "40px;" }} />
    );
  };

  const addSites = () => {
    setAddSitesModal(true);
  };

  const addContacts = () => {
    setAddContactsModal(true);
  };

  const handlePopTypeChange = (event) => {
    const selectedPopType = event.target.value;
    setIsEquipmentSelected(selectedPopType === "Equipment");
    setFormData({
      ...formData,
      popType: selectedPopType,
    });

    dispatch(resetAllPops());
  };

  const handleGracePeriod = (event) => {
    const selectedGracePeriod = event.target.value;

    setFormData({
      ...formData,
      gracePeriod: selectedGracePeriod, // Update gracePeriod in formData
    });
  };

  // Fetch plan types from the API and set them in the state
  const [accountDetails, setAccountDetails] = useState({});

  const fetchPlanTypes = async () => {
    try {
      const response = await CallGETAPI("pop/get-plans");
      const planTypesData = response?.data?.data || [];
      if (planTypesData?.length > 0) {
        setPlanTypes(planTypesData);
        // planTypes.push(planTypesData)
      }

      if (accountId) {
        const AccDetails = await FetchAccountDetails(accountId);
        setAccountDetails(AccDetails);
      }

      // let cart = await GetCertAgencyList();
      // if (cart.status) {
      //     let CertAgencyData = cart?.data?.agenciesList;
      //     let allCertAgencyData = prepareOptions(
      //         CertAgencyData,
      //         "certifying_agency_id",
      //         "certifying_agency_name"
      //     );
      //     setCertifyAgency(allCertAgencyData);
      // }
    } catch (error) {
      console.error("Error fetching plan types:", error);
    }
  };

  // Function to handle Plan Type selection
  const handlePlanTypeChange = async (selectedOption) => {
    setSelectedPlanType(selectedOption);
  };

  const fetchData = async () => {
    try {
      const response = await CallGETAPI(
        `pop/get-plan-by-id/${selectedPlanType}`
      );
      if (response) {
        setGetPlanType(response?.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedPlanType) {
      fetchData();
    }
  }, [selectedPlanType]);

  // export const addDollarSign = (value) =>
  // {
  // 	value = value.replace('$', '');
  // 	const regex = /^\d*\.?\d*$/;

  // 	if (regex.test(value))
  // 	{
  // 		return '$' + value;
  // 	} else
  // 	{
  // 		return '$';
  // 	}
  // }
  const [ofYearList, setOfYearList] = useState([]);

  const handleChange = (e, fieldName) => {
    let value = e.target.value;
    if (e.target.type === "checkbox") {
      if (e.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
    }

    if (e.target.name === "numberOfYears") {
      // Parse the value from the input
      const numYears = parseInt(e.target.value);

      // Initialize the array
      let ar1 = [];

      // Check if the parsed number is valid
      if (!isNaN(numYears) && numYears >= 0) {
        // Populate the array based on the number of years
        for (let i = 0; i <= numYears; i++) {
          if (i === 0) {
            ar1[0] = "Base";
          } else {
            ar1.push(i);
          }
        }
      } else {
        // Handle the case where the input is invalid or not a number
        ar1 = [1]; // Default value or handle as needed
      }

      // Update the state
      setOfYearList(ar1);
    }

    const oldData = { ...formData };

    // if(fieldName==='RMSMonthlyPrice'){
    //   oldData[fieldName]  = PriceFormat(value);
    // }else{
    //   oldData[fieldName]  = value;
    // }
    oldData[fieldName] = value;

    setFormData(oldData);
  };
  // contract year change value
  // handle calendar change
  const handleCalendarChange = (name, date) => {
    let dateValue = null;
    if (date) {
      setValidatedContract(false);
      // const formattedDate = dayjs(date).format("MM/DD/YYYY");
      const formattedDate = date ? FormatDate(date) : "";
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedDate,
      }));
    }
  };

  const [accountContact, setAccountContact] = useState([]);
  const [selectedContact, setSelectedContacts] = useState({
    contract_officer: [],
    contracting_officer_rep: [],
    other_reps: [],
  });
  // const [contactRepsList,    ] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);

  const fetchContactByAccount = async () => {
    let AccountContactList = await ContactList(accountId);

    if (AccountContactList) {
      setAccountContact(AccountContactList);
    }
  };
  useEffect(() => {
    fetchPlanTypes();
    fetchContactByAccount();
  }, []);
  useEffect(() => {
    // Reset the value only if the current contractYear is no longer in the updated list
    if (!ofYearList.includes(formData.contractYear)) {
      setFormData((prev) => ({ ...prev, contractYear: "" }));
    }
  }, [ofYearList]);

  const adjustTextareaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "10px"; // Reset height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set to scroll height
    }
  };

    const fetchOnload = async () => {
       const response = await CallGETAPI('site/account-site-list/' + accountId);
          const sitesdetails = response?.data?.data?.site_details || [];
        setProductModalData(sitesdetails);
      }

  // Adjust textarea height when content changes
  useEffect(() => {
    adjustTextareaHeight();
  }, [formData.invoicingInstructions]);

  useEffect(() => {
    const checkSelectedContact =
      selectedContact?.contract_officer?.length > 0 ||
      selectedContact?.contracting_officer_rep?.length > 0 ||
      selectedContact?.other_reps?.length > 0;
    if (!checkSelectedContact) {
      // setShowContactsError(true);
    } else {
      setShowContactsError(false);
    }
  }, [selectedContact]);

    useEffect(() => {
      fetchOnload();
    }, []);

  return (
    <>
      <div
        className="mt-4"
        style={{ position: "relative", width: "100%", paddingInline: "45px" }}
      >
        <SubHeading
          hideNew="tab"
          title={
            "Account : " +
            (accountDetails?.account_name ? accountDetails?.account_name : "")
          }
          newUrl="/new-account"
          subHeading={true}
          hideHierarchy={true}
        />

        <Form
          className=""
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
          id="create-new-equipment-form"
        >
           <div className="container-fluid">
           <div
              className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
              style={{
                background: "#eee",
                border: "2px solid #00578E",
                borderRadius: "5px"
              }}
              id="main_account_section"
            >
            <h2 className="heading">Period of Performance Information</h2>
            {/* <div className="row my-3"> */}

            <div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>      
  {/* Left Column */}
  <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' ,marginLeft: "100px"}}>

  <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          flexDirection: "column",
          gap: "1px",
        }}
      >
                    <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                    <Form.Label
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#333',
            width: '118px',
            margin: 0,
            textAlign: 'right',
          }}
        >
          Contract Start*
        </Form.Label>
        <div
          // className={
          //   validatedContract
          //     ? "d-flex align-items-center calendar-input-btn invalid-datepicker-div"
          //     : "d-flex align-items-center calendar-input-btn"
          // }
          style={{
            padding: '6px 10px',
            fontSize: '15px',
            height: '34px',
            width: '400px',
            minWidth: "420px",
            marginBottom: "10px"
          }}
          isInvalid={validated && !formData.contractStart} // Explicitly set isInvalid              
        >
          <CommonDatePicker
            calName={"contractStart"}
            CalVal={
              formData.contractStart ? FormatDate(formData.contractStart) : null
            }
            HandleChange={(name, val) => handleCalendarChange(name, val)}
            disabled={false}
          />
        </div>
        </div>
            {/* {validatedContract && (
                      <div className="invalid mt-2">
                        This field is required.
                      </div>
                    )} */}
                       <Form.Control.Feedback type="invalid"
                                          style={{
                                            marginTop: "5px",
                                            marginLeft: "130px",
                                            display: validated && !formData.contractStart ? "block" : "none",
                                          }}>
                                          Please Enter Zip code.
                                        </Form.Control.Feedback>
      </Form.Group>
    </div>

     {/* POP Type */}
     <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          flexDirection: "column",
          gap: '1px',
        }}
      >
       <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
       <Form.Label
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#333',
            width: '140px',
            margin: 0,
            textAlign: 'right',
          }}
        >
          POP Type*
        </Form.Label>
        <div className="custom-select-wrapper">
          <select
            className="form-control"
            name="popType"
            value={formData.popType}
            onChange={(e) => {
              handlePopTypeChange(e);
              if (e.target.value !== "") {
                setShowPopError(false);
              }
            }}
            required
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '6px 10px',
              fontSize: '15px',
              // color: '#999',
              height: '34px',
              width: '400px',
              // background: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12\" height=\"12\" viewBox=\"0 0 24 24\"><path fill=\"%23000\" d=\"M7 10l5 5 5-5z\"/></svg>') no-repeat right 10px center",
              backgroundSize: '12px',
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
            }}
          >
            <option value="">--Select One--</option>
            <option value="Equipment">Equipment</option>
            <option value="Training">Training</option>
          </select>
        </div>
        </div>
        {ShowPopError && (
          <div className="invalid mt-2" style={{ marginLeft: '130px' }}>
            This field is required.
          </div>
        )}
      </Form.Group>
    </div>

        {/* Plan Type (Conditional) */}
        {formData?.popType === "Equipment" && planTypes.length > 0 && (
      <div className="row-my-4">
        <Form.Group
          className="form-group-responsive"
          style={{
            marginBottom: '10px',
            display: 'flex',
            flexDirection: "column",
            gap: '1px',
          }}
        > 
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>        
          <Form.Label
            style={{
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#333',
              width: '120px',
              margin: 0,
              textAlign: 'right',
            }}
          >
            Plan Type*
          </Form.Label>
         <Form.Select
            className="form-control"
            name="planType"
            value={selectedPlanType}
            onChange={(e) => handlePlanTypeChange(e.target.value)}
            required
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '6px 10px',
              fontSize: '15px',
              height: '34px',
              width: '400px',
              backgroundSize: '12px',
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
            }}
            isInvalid={validated && !selectedPlanType} // Explicitly set isInvalid                 
          >
            <option value="">- Select Plan Type -</option>
            {planTypes.map((planType) => (
              <option key={planType.id} value={planType.id}>
                {planType.plan_name}
              </option>
            ))}
        </Form.Select>
          </div>
          <Form.Control.Feedback type="invalid" 
           style={{
            marginLeft: "130px",
            display: validated && !selectedPlanType ? "block" : "none",
          }}>
            This field is required
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    )}

      {/* Grace Period */}
      <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          flexDirection: "column",
          gap: '1px',
        }}
      >
         <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
        <Form.Label
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#333',
            width: '140px',
            margin: 0,
            textAlign: 'right',
          }}
        >
          Grace Period*
        </Form.Label>
        <div className="custom-select-wrapper">
          <select
            className="form-control custom-select"
            name="gracePeriod"
            value={formData.gracePeriod}
            onChange={(e) => {
              handleGracePeriod(e);
              if (e.target.value !== "") {
                setShowGraceError(false);
              }
            }}
            required
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '6px 10px',
              fontSize: '15px',
              height: '34px',
              width: '400px',
              backgroundSize: '12px',
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
            }}
          >
            <option value="">--Select One--</option>
            <option value="15">15 Days</option>
            <option value="30">30 Days</option>
            <option value="45">45 Days</option>
            <option value="60">60 Days</option>
          </select>
        </div>
        </div>
        {ShowGraceError && (
          <div className="invalid mt-2" style={{ marginLeft: '130px' }}>
            This field is required.
          </div>
        )}
      </Form.Group>
    </div>

    {/* QB Invoice # */}
    <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Form.Label
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#333',
            width: '120px',
            margin: 0,
            textAlign: 'right',
          }}
        >
          QB Invoice #
        </Form.Label>
        <Form.Control
          type="text"
          name="qbInvoiceNumber"
          value={formData.qbInvoiceNumber}
          onChange={(e) => handleChange(e, "qbInvoiceNumber")}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '6px 10px',
            fontSize: '15px',
            color: '#333',
            height: '34px',
            width: '400px',
          }}
        />
      </Form.Group>
    </div>

    {/* Invoice Paid */}
    <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Form.Label
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#333',
            width: '120px',
            margin: 0,
            textAlign: 'right',
          }}
        >
          Invoice Paid
        </Form.Label>
        <CustomToggleButton
          ToggleName="invoicePaid"
          ToggleValue={formData?.invoicePaid}
          changeHandler={(e) => handleChange(e, "invoicePaid")}
          style={{ height: '7px' }}
        />
      </Form.Group>
    </div>
  </div>

  {/* Right Column */}
  <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>

    {/* # of Year */}
    <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Form.Label
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#333',
            width: '120px',
            margin: 0,
            textAlign: 'right',
          }}
        >
          # of Year
        </Form.Label>
        <Form.Control
          type="number"
          name="numberOfYears"
          value={formData.numberOfYears}
          onChange={(e) => handleChange(e, "numberOfYears")}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '6px 10px',
            fontSize: '15px',
            color: '#333',
            height: '34px',
            width: '400px',
          }}
        />
      </Form.Group>
    </div>

    {/* Contract Year */}
    <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Form.Label
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#333',
            width: '145px',
            margin: 0,
            textAlign: 'right',
          }}
        >
          Contract Year
        </Form.Label>
        <div className="custom-select-wrapper">
          <select
            className="form-control custom-select"
            value={formData.contractYear}
            name="contractYear"
            onChange={(e) => handleChange(e, "contractYear")}
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '6px 10px',
              fontSize: '15px',
              height: '34px',
              width: '400px',
              backgroundSize: '12px',
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
            }}
          >
            <option value="">--Select One--</option>
            {ofYearList.length > 0
              ? ofYearList.map((it) => (
                  <option key={it} value={it}>
                    {it}
                  </option>
                ))
              : ""}
                 <div className="custom-arrow"></div>
          </select>
       
        </div>
      </Form.Group>
    </div>

    
    {/* Contract # */}
    <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          flexDirection: "column",
          gap: "1px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Form.Label
            style={{
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#333',
              width: '120px',
              margin: 0,
              textAlign: 'right',
            }}
          >
            Contract #
          </Form.Label>
          <Form.Control
            type="text"
            name="contractNumber"
            value={formData.contractNumber}
            onChange={(e) => handleChange(e, "contractNumber")}
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '6px 10px',
              fontSize: '15px',
              color: '#333',
              height: '34px',
              width: '400px',
            }}
          />
        </div>
      </Form.Group>
    </div>

       {/* Req # */}
       <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Form.Label
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#333',
            width: '120px',
            margin: 0,
            textAlign: 'right',
          }}
        >
          Req #
        </Form.Label>
        <Form.Control
          type="text"
          name="reqNumber"
          value={formData.reqNumber}
          onChange={(e) => handleChange(e, "reqNumber")}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '6px 10px',
            fontSize: '15px',
            color: '#333',
            height: '34px',
            width: '400px',
          }}
        />
      </Form.Group>
    </div>

     {/* Order # */}
     <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Form.Label
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#333',
            width: '120px',
            margin: 0,
            textAlign: 'right',
          }}
        >
          Order #
        </Form.Label>
        <Form.Control
          type="text"
          name="orderNumber"
          value={formData.orderNumber}
          onChange={(e) => handleChange(e, "orderNumber")}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '6px 10px',
            fontSize: '15px',
            color: '#333',
            height: '34px',
            width: '400px',
          }}
        />
      </Form.Group>
    </div>

    {/* Modification # */}
    <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Form.Label
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#333',
            width: '120px',
            margin: 0,
            textAlign: 'right',
          }}
        >
          Modification #
        </Form.Label>
        <Form.Control
          type="text"
          name="modificationNumber"
          value={formData.modificationNumber}
          onChange={(e) => handleChange(e, "modificationNumber")}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '6px 10px',
            fontSize: '15px',
            color: '#333',
            height: '34px',
            width: '400px',
          }}
        />
      </Form.Group>
    </div>


    {/* Invoicing Instructions */}
    {/* <div className="row-my-4">
      <Form.Group
        className="form-group-responsive"
        style={{
          marginBottom: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '1px',
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Form.Label
            style={{
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#333',
              width: '120px',
              margin: 0,
              textAlign: 'right',
            }}
          >
            Invoicing Instructions
          </Form.Label>
          <Form.Control
            as="textarea"
            ref={textAreaRef}
            name="invoicingInstructions"
            value={formData.invoicingInstructions}
            onChange={(e) => handleChange(e, "invoicingInstructions")}
            style={{
              wordWrap: "break-word",
              overflowWrap: "break-word",
              overflow: "hidden",
              height: "10px",
              resize: "none",
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '6px 10px',
              fontSize: '15px',
              color: '#333',
              width: '400px',
            }}
          />
        </div>
      </Form.Group>
    </div> */}
  </div>
  </div>

</div>


<div
              className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
              style={{
                background: "#eee",
                border: "2px solid #00578E",
                borderRadius: "5px"
              }}
              id="main_account_section"
            >

              
            <div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>      
  {/* Left Column */}
  <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
            <h2 className="heading">Sites*</h2>
            </div>

            <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' ,marginLeft: "100px", textAlign: "left"}}>
            <h2 className="heading" style={{textAlign: "left"}}>Contacts</h2>
              </div>
              </div>

            <div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>      
  {/* Left Column */}
  <div className="col my-0 form-column" style={{ width: '45%', padding: '0 0px' ,marginLeft: "100px"}}>
  <div className="row-my-4">
  <Form.Group
    className="form-group-responsive"
    style={{
      marginBottom: '10px',
      display: 'flex',
      flexDirection: "column",
      gap: '1px',
    }}
  >
    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
    <Form.Label
      style={{
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#333',
        width: '120px',
        margin: 0,
        textAlign: 'right',
      }}
    >
      Sites*
    </Form.Label>
    <div
      style={{
        position: 'relative',
        width: '400px',
      }}
    >
      <MultiSelect
        options={ProductModalData.map((site) => ({
          label: site.account_site_name,
          value: site.account_site_info_id,
        }))}
        value={selectedSites}
        onChange={setSelectedSites}
        labelledBy="--Select One--"
        hasSelectAll={false}
        className="custom-multi-select"
        overrideStrings={{
          selectSomeItems: "--Select One--",
        }}
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '6px 10px',
          fontSize: '15px',
          height: '34px',
          width: '400px',
          backgroundSize: '12px',
        }}
      />
    </div>
    </div>
    {selectedSites.length <= 0 && showSitesError && (
      <div className="invalid mt-2" style={{ marginLeft: '130px' }}>
        This field is required.
      </div>
    )}
  </Form.Group>
</div>
    </div>

    <div className="col my-0 form-column" style={{ width: '45%', padding: '0 0px' }}>
    <div className="row-my-4">
  <Form.Group
    className="form-group-responsive"
    style={{
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    }}
  >
    <Form.Label
      style={{
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#333',
        width: '120px',
        margin: 0,
        textAlign: 'right',
      }}
    >
      Contract Officer
    </Form.Label>
    <div
      style={{
        position: 'relative',
        width: '400px',
      }}
    >
      <MultiSelect
        options={accountContact.map((contact) => ({
          label: contact.contact_name,
          value: contact.contact_id,
        }))}
        value={selectedContact.contract_officer}
        onChange={(selected) => setSelectedContacts({ ...selectedContact, contract_officer: selected })}
        labelledBy="--Select One--"
        hasSelectAll={false}
        className="custom-multi-select"
        overrideStrings={{
          selectSomeItems: "--Select One--",
        }}
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '6px 10px',
          fontSize: '15px',
          height: '34px',
          width: '400px',
          backgroundSize: '12px',
        }}
      />
    </div>
  </Form.Group>
</div>

<div className="row-my-4">
  <Form.Group
    className="form-group-responsive"
    style={{
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    }}
  >
    <Form.Label
      style={{
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#333',
        width: '120px',
        margin: 0,
        textAlign: 'right',
      }}
    >
      Contract Officer Rep
    </Form.Label>
    <div
      style={{
        position: 'relative',
        width: '400px',
      }}
    >
      <MultiSelect
        options={accountContact.map((contact) => ({
          label: contact.contact_name,
          value: contact.contact_id,
        }))}
        value={selectedContact.contracting_officer_rep}
        onChange={(selected) => setSelectedContacts({ ...selectedContact, contracting_officer_rep: selected })}
        labelledBy="--Select One--"
        hasSelectAll={false}
        className="custom-multi-select"
        overrideStrings={{
          selectSomeItems: "--Select One--",
        }}
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '6px 10px',
          fontSize: '15px',
          height: '34px',
          width: '400px',
          backgroundSize: '12px',
        }}
      />
    </div>
  </Form.Group>
</div>

<div className="row-my-4">
  <Form.Group
    className="form-group-responsive"
    style={{
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    }}
  >
    <Form.Label
      style={{
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#333',
        width: '120px',
        margin: 0,
        textAlign: 'right',
      }}
    >
      Other Rep
    </Form.Label>
    <div
      style={{
        position: 'relative',
        width: '400px',
      }}
    >
      <MultiSelect
        options={accountContact.map((contact) => ({
          label: contact.contact_name,
          value: contact.contact_id,
        }))}
        value={selectedContact.other_reps}
        onChange={(selected) => setSelectedContacts({ ...selectedContact, other_reps: selected })}
        labelledBy="--Select One--"
        hasSelectAll={false}
        className="custom-multi-select"
        overrideStrings={{
          selectSomeItems: "--Select One--",
        }}
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '6px 10px',
          fontSize: '15px',
          height: '34px',
          width: '400px',
          backgroundSize: '12px',
        }}
      />
    </div>
  </Form.Group>
</div>

    </div>
    </div>
    </div>
          </div>

          <div className="row pb-3 py-5">
            <div className="col-12 content-flex-right">
              <button
                className="btn btn-danger text-uppercase"
                type="button"
                onClick={() => {
                  const is_active = 0
                  handleRemoveDispatch(accountId, is_active)
                  navigate(-1);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success text-uppercase ms-2"
                type="submit"

                disabled={loading}

              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </Form >

        {loading && (
          <div style={overlay}>
            <Spinner style={{ width: "3rem", height: "3rem" }} />
          </div>
        )}

      </div>
    </>
  );
};

export default NewPop;
