import React, { useEffect, useState } from "react";
import { isSubAdminPermission } from "../../../../../common/helper/permission";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import servicecheck from "../../../../../common/img/ServiceCheck.svg";
import { useDispatch } from "react-redux";
import { addNewBreadcrumbs } from "../../../../../store/slices/breadcrumbsSlice";
import { isPending } from "@reduxjs/toolkit";
import { isPermission } from "../../../../../common/helper/PermissionManager";
import {
  DecryptToken,
  fetchAedLength,
} from "../../../../../common/helper/BasicFn";
import { printConsole } from "../../../../../common/helper/Common";

const TopAedNewMove = ({
  accountId,
  accountName,
  siteId,
  siteName,
  newAed = 1,
  moveAed = 0,
  isserviceCheck = 0,
  hideAccMove = 0,
  hideAedMove = 0,
  isTechnician = false,
  aedAccessoryCount,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = DecryptToken();
  const [aedLength, setaedLength] = useState();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
  };

  // const fetchAedLengthApi = async () => {
  //   const aedLength = await fetchAedLength(accountId);
  //   setaedLength(aedLength);
  // };

  // useEffect(() => {
  //   fetchAedLengthApi();
  // }, []);

  printConsole({ siteName }); // print console.log
  const handleMoveAccesoryDispatch = (accountId, siteId) => {
    BreadCrumbsObject.title = "Move Accessory";
    BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/move-accessory/${accountId}/${siteId}`,
      })
    );
  };
  const handleMoveAedDispatch = (accountId, siteId) => {
    BreadCrumbsObject.title = "Move Aed";
    BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/move-aed/` + accountId + "/" + siteId,
      })
    );
  };
  const handleServiceCheckDispatch = (accountId, siteId) => {
    BreadCrumbsObject.title = "Service Check";
    BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/aed/service-check/" + accountId + "/" + siteId,
      })
    );
  };
  const handleAedCheckDispatch = (accountId, siteId) => {
    BreadCrumbsObject.title = "Aed Check";
    BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/aed/aed-check/" + accountId + "/" + siteId,
      })
    );
  };
  const handleNewAccessoryDispatch = (accountId, siteId) => {
    BreadCrumbsObject.title = "New Accessory Form";
    BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;
    printConsole(`/account/aed/NewStandloneAcce/${accountId}/${siteId}`); // print console.log
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/aed/NewStandloneAcce/${accountId}/${siteId}`,
      })
    );
  };
  const handleNewAedDispatch = (accountId, siteId) => {
    BreadCrumbsObject.title = "New Aed Form";
    BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/new/aed/${accountId}/${siteId}`,
      })
    );
  };

  //   const handleMoveAedDispatch = () => {
  //     BreadCrumbsObject.title = "Move Aed";
  //     console.log(`/move-aed/` + accountId + "/" + siteId)
  //     dispatch(addNewBreadcrumbs({ ...BreadCrumbsObject, url: `/move-aed/` + accountId + "/" + siteId }))

  // }
  // const handleServiceCheckDispatch = () => {
  //     BreadCrumbsObject.title = "Service Check ";
  //     dispatch(addNewBreadcrumbs({ ...BreadCrumbsObject, url: "/account/aed/service-check/" + accountId + "/" + siteId }))

  // }
  // const handleNewAccessoryDispatch = () => {
  //     BreadCrumbsObject.title = "New Accessory Form";
  //     console.log(`/account/aed/NewStandloneAcce/${accountId}/${siteId}`)
  //     dispatch(addNewBreadcrumbs({ ...BreadCrumbsObject, url: `/account/aed/NewStandloneAcce/${accountId}/${siteId}` }))

  // }
  // const handleNewAedDispatch = () => {
  //     BreadCrumbsObject.title = "New Aed Form";
  //     dispatch(addNewBreadcrumbs({ ...BreadCrumbsObject, url: `/account/new/aed/${accountId}/${siteId}` }))

  // }

  return (
    <>
      {Number(isPermission({ type: "btn", name: "newAed" })) === 1 ||
      Number(isPermission({ type: "btn", name: "newAccessory" })) === 1 ? (
        <>
          <Dropdown>
            {newAed && (
              <Dropdown.Toggle
                className="tab-btn btn btn-transparent text-primary ms-2 bg-white DropDownBtn"
                id={`new-tab-button-${siteId}`}
                style={{
                  backgroundColor: "transparent !important",
                  border: "none",
                }}
              >
                <img className="" src="/assets/images/add.svg" alt="New" />
                <span className="ms-1 textSize">New</span>
              </Dropdown.Toggle>
              // ) : (
              //   ""
            )}

            <Dropdown.Menu className="" style={{ minWidth: "20px" }}>
              {Number(isPermission({ type: "btn", name: "newAccessory" })) ===
              1 ? (
                <Dropdown.Item
                  className="DropDownListtextSize"
                  onClick={() => {
                    handleNewAccessoryDispatch(accountId, siteId);
                    navigate(
                      `/account/aed/NewStandloneAcce/${accountId}/${siteId}`,
                      {
                        state: {
                          selectedAccount: {
                            accountId: accountId,
                            accountName: accountName,
                          },
                          selectedSite: {
                            siteId: siteId,
                            siteName: siteName,
                          },
                        },
                      }
                    );
                  }}
                >
                  Accessories
                </Dropdown.Item>
              ) : (
                ""
              )}

              {Number(isPermission({ type: "btn", name: "newAed" })) === 1 ? (
                <Dropdown.Item
                  className="DropDownListtextSize"
                  onClick={() => {
                    handleNewAedDispatch(accountId, siteId);
                    navigate(`/account/new/aed/${accountId}/${siteId}`, {
                      state: {
                        selectedAccount: {
                          accountId: accountId,
                          accountName: accountName,
                        },
                        selectedSite: {
                          siteId: siteId,
                          siteName: siteName,
                        },
                      },
                    });
                  }}
                >
                  AED
                </Dropdown.Item>
              ) : (
                ""
              )}
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        ""
      )}

      {Number(isPermission({ type: "btn", name: "moveAed" })) === 1 ||
      Number(isPermission({ type: "btn", name: "moveAccessory" })) === 1 ? (
        <>
          {/* {moveAed && (hideAccMove === 0 || hideAedMove === 0) ? ( */}
          {Number(hideAedMove) > 0 || Number(hideAccMove) > 0 ? (
            <Dropdown>
              <Dropdown.Toggle
                className="tab-btn btn btn-transparent text-primary ms-2 bg-white DropDownBtn"
                id={`new-tab-button-${siteId}`}
                style={{
                  backgroundColor: "transparent !important",
                  border: "none",
                }}
              >
                <img src="/assets/images/add.svg" alt="Move" />
                <span className="ms-1 textSize">Move</span>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="bg-primary"
                style={{ minWidth: "20px" }}
              >
                {Number(hideAccMove) > 0 &&
                Number(isPermission({ type: "btn", name: "moveAccessory" })) ===
                  1 ? (
                  <Dropdown.Item
                    className="DropDownListtextSize"
                    onClick={() => {
                      handleMoveAccesoryDispatch(accountId, siteId);
                      navigate(`/move-accessory/${accountId}/${siteId}`, {
                        state: {
                          selectedAccount: {
                            accountId: accountId,
                            accountName: accountName,
                          },
                          selectedSite: {
                            siteId: siteId,
                            siteName: siteName,
                          },
                        },
                      });
                    }}
                  >
                    Accessories
                  </Dropdown.Item>
                ) : (
                  ""
                )}

                {Number(hideAedMove) > 0 &&
                  isPermission({ type: "btn", name: "moveAed" }) === 1 && (
                    <Dropdown.Item
                      className="DropDownListtextSize"
                      onClick={() => {
                        handleMoveAedDispatch(accountId, siteId);
                        navigate(`/move-aed/` + accountId + "/" + siteId, {
                          state: {
                            selectedAccount: {
                              accountId: accountId,
                              accountName: accountName,
                            },
                            selectedSite: {
                              siteId: siteId,
                              siteName: siteName,
                            },
                          },
                        });
                      }}
                    >
                      AED
                    </Dropdown.Item>
                  )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {isserviceCheck &&
      isTechnician &&
      isPermission({ type: "btn", name: "newServiceCheck" }) === 1 ? (
        //  && Number(isPermission({ type: "btn", name: "serviceCheck" })) === 1 ?
        <button
          className="btn text-primary serviceCheckbtn"
          type="button"
          id={`service_check_btn_${siteId}`}
          onClick={(e) => {
            handleServiceCheckDispatch(accountId, siteId);
            navigate("/account/aed/service-check/" + accountId + "/" + siteId, {
              state: { siteName: siteName, key: "serviceCheck" },
            });
          }}
        >
          <img
            className="serviceCheckImage"
            src={servicecheck}
            alt="svg"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
            }}
          />
          <span className="ms-1 textSize">Service Check</span>
        </button>
      ) : (
        ""
      )}
      {(isserviceCheck &&
        isPermission({ type: "btn", name: "newAedCheck" }) === 1) ||
      user.assign_aed ? (
        //  && Number(isPermission({ type: "btn", name: "AedCheck" })) === 1 ?
        <button
          className="btn text-primary serviceCheckbtn"
          type="button"
          id={`service_check_btn_${siteId}`}
          onClick={(e) => {
            handleAedCheckDispatch(accountId, siteId);
            navigate("/account/aed/aed-check/" + accountId + "/" + siteId, {
              state: {
                siteId: siteId,
                siteName: siteName,
                key: "aedCheck",
              },
            });
          }}
        >
          <img
            className="serviceCheckImage"
            src={"/assets/images/aedcheck.svg"}
            alt="svg"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
            }}
          />
          <span className="ms-1 textSize">AED Check</span>
        </button>
      ) : (
        ""
      )}
    </>
  );
};

export default TopAedNewMove;
