import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import New from "../../../../common/img/New.png";
import Cancel from "../../../../common/img/Cancel.svg";
import Check from "../../../../common/img/Check.svg";
import { toast } from "react-toastify";
import Edit from "../../../../common/img/Edit.png";
import {
  DecryptToken,
  formatPhoneNumber,
  getPermission,
} from "../../../../common/helper";
import ContactName from "../../../../common/components/ContactName";
import { AED_IMG_BASE, CallGETAPI } from "../../../../common/services";
import Moment from "react-moment";
import { Box, Skeleton } from "@mui/material";
import SiteCoordinatorInformation from "../common/SiteCoordinatorInformation";
import SiteTranningAddress from "../common/SiteTranningAddress";
import SiteBottom from "../../../../common/components/custom-ui/SiteBottom";
import BillingShipping from "../common/BillingShipping";
import SiteHours from "../common/SiteHours";
import AedSiteUnits from "../common/AedSiteUnits";
import DynamicTblSkeleton from "../../../accounts/components/skeleton/table/DynamicTblSkeleton";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { printConsole } from "../../../../common/helper/Common";

export default function SiteDetails({
  siteData,
  setSiteData,
  siteHoursData,
  billingData,
  shippingData,
  trainingData,
  aedUnits,
  coordinatorData,
  fetch,
  setEditSiteAdmin,
}) {
  const { siteId } = useParams();
  const [siteInfo, setSiteInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  printConsole("accountName", location.state); // print console.log

  const fetchData = async () => {
    setLoading(true);
    const result = await CallGETAPI("site/site-information/" + siteId);
    if (result?.data?.status) {
      const resultData = result?.data?.data?.siteData || {};
      console.log({resultData})
      setSiteInfo(resultData);
      setSiteData(resultData);
      setEditSiteAdmin((prev) => ({
        ...prev,
        accountName: location?.state?.accountName,
        siteInfo: resultData,
      }));
    }
    setLoading(false);
  };
  useEffect(() => {
    // /site/site-information/
    fetchData();
  }, []);

  return (
    <div className="containerr site-details-table-section">
      <div className=" ">
        <table className="no-border">
          <tr className="bg-blue no-border">
            <th
              colSpan={4}
              className="tbl-title text-left no-border"
              style={{ backgroundColor: "ghostwhite" }}
            >
              Site Information
            </th>
          </tr>
          {/* {loading ? 
          <TableSkeleton 
            row={4}
            columns={4}
          />
        : <> */}
          <tr>
            <th colSpan={1}>Site Phone</th>
            <th colSpan={2}>Address</th>
            <th colSpan={1}>Main</th>
            <th colSpan={1}>Site Status</th>
          </tr>
          <tr>
            <td colSpan={1}>
              {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) : (
                siteInfo?.account_site_phone && (
                  <a
                    className="link"
                    style={{ textDecoration: "none" }}
                    href={
                      "tel:" +
                      siteInfo?.account_site_phone_ext +
                      siteInfo?.account_site_phone
                    }
                  >
                    {siteInfo?.account_site_phone
                      ? formatPhoneNumber(siteInfo.account_site_phone)
                      : ""}{" "}
                    {siteInfo?.account_site_phone_ext
                      ? " X " + siteInfo?.account_site_phone_ext
                      : ""}
                  </a>
                )
              )}
            </td>
            <td colSpan={2}>
              {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) : (
                <>
                  {`${siteInfo?.building_name ?? ""} ${siteInfo?.account_site_address1 ?? ""}, `}
                  {siteInfo?.account_site_address2 ? `${siteInfo?.account_site_address2}, ` : ""}
                  { siteInfo?.account_site_city ? `${siteInfo?.account_site_city}, ` : ""}
                  {`${siteInfo?.account_site_state ?? ""} ${siteInfo?.account_site_zipcode ?? ""}, `}
                  {siteInfo?.account_site_country ?? ""}
                </>
              )}
            </td>

            <td colSpan={1}>
              {" "}
              {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) : Number(siteInfo?.account_site_main_site) === 0 ? (
                <img
                  className="col"
                  style={{ maxWidth: "20%", height: "20px" }}
                  src={Cancel}
                />
              ) : (
                <img
                  className="col"
                  style={{ maxWidth: "20%", height: "25px" }}
                  src={Check}
                />
              )}
            </td>
            <td colSpan={1}>{siteInfo?.account_site_status_name}</td>
          </tr>

          {Number(isPermission({ type: "data", name: "siteDetailsToggle" })) ===
          1 ? (
            <>
              <tr>
                <th colSpan={1}>Invoice ASAP</th>
                <th colSpan={1}>Call Ahead</th>
                <th colSpan={1}>Security Clearance</th>
                <th colSpan={1}>Requires Escort</th>
                <th colSpan={1}>Out of Area</th>
              </tr>
              <tr>
                <td>
                  {loading ? (
                    <>
                      <Skeleton
                        count={5}
                        width={"90%"}
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    </>
                  ) : Number(siteInfo?.invoice_asap) === 0 ? (
                    <img
                      className="col"
                      style={{ maxWidth: "20%", height: "20px" }}
                      src={Cancel}
                    />
                  ) : (
                    <img
                      className="col"
                      style={{ maxWidth: "20%", height: "25px" }}
                      src={Check}
                    />
                  )}
                </td>

                <td>
                  {loading ? (
                    <>
                      <Skeleton
                        count={5}
                        width={"90%"}
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    </>
                  ) : Number(siteInfo?.account_site_call_ahead) === 0 ? (
                    <img
                      className="col"
                      style={{ maxWidth: "20%", height: "20px" }}
                      src={Cancel}
                    />
                  ) : (
                    <img
                      className="col"
                      style={{ maxWidth: "20%", height: "25px" }}
                      src={Check}
                    />
                  )}
                </td>

                <td>
                  {loading ? (
                    <>
                      <Skeleton
                        count={5}
                        width={"90%"}
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    </>
                  ) : Number(siteInfo?.account_site_call_ahead) === 0 ? (
                    <img
                      className="col"
                      style={{ maxWidth: "20%", height: "20px" }}
                      src={Cancel}
                    />
                  ) : (
                    <img
                      className="col"
                      style={{ maxWidth: "20%", height: "25px" }}
                      src={Check}
                    />
                  )}
                </td>

                <td>
                  {loading ? (
                    <>
                      <Skeleton
                        count={5}
                        width={"90%"}
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    </>
                  ) : Number(siteInfo?.requires_escort) === 0 ? (
                    <img
                      className="col"
                      style={{ maxWidth: "20%", height: "20px" }}
                      src={Cancel}
                    />
                  ) : (
                    <img
                      className="col"
                      style={{ maxWidth: "20%", height: "25px" }}
                      src={Check}
                    />
                  )}
                </td>

                <td>
                  {loading ? (
                    <>
                      <Skeleton
                        count={5}
                        width={"90%"}
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    </>
                  ) : Number(siteInfo?.out_of_area) === 0 ? (
                    <img
                      className="col"
                      style={{ maxWidth: "20%", height: "20px" }}
                      src={Cancel}
                    />
                  ) : (
                    <img
                      className="col"
                      style={{ maxWidth: "20%", height: "25px" }}
                      src={Check}
                    />
                  )}
                </td>
              </tr>
            </>
          ) : (
            ""
          )}
          {/* </>} */}
        </table>

        {siteInfo?.site_hours_toggle === 1 && (
          <>
            <SiteHours siteId={siteId} setEditSiteAdmin={setEditSiteAdmin} />
          </>
        )}

        {/* billing */}
        <BillingShipping
          siteId={siteId}
          accountId={siteData?.account_id}
          setEditSiteAdmin={setEditSiteAdmin}
        />
        {/* AED information */}
        <AedSiteUnits siteId={siteId} />

        {/* Training information */}
        <SiteTranningAddress siteId={siteId} siteData={siteInfo} />

        {/* Coordinator Information */}
        <SiteCoordinatorInformation
          accountId={siteData?.siteInfo?.account_id}
          siteId={siteId}
          setEditSiteAdmin={setEditSiteAdmin}
        />
      </div>

      <SiteBottom
        created_date={siteInfo?.created_date}
        created_by={siteInfo?.created_by}
        modified_date={siteInfo?.modified_date}
        modified_by={siteInfo?.modified_by}
      />
    </div>
  );
}
