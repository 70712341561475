import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CallGETAPI } from "../../../../common/services";
import {
  GroupBYAdminSiteCoordinatorInfo,
  truncateText,
} from "../../../../common/helper/BasicFn";
import { Skeleton } from "@mui/material";
import { isPermission } from "../../../../common/helper/PermissionManager";

const BillingShipping = ({ siteId, accountId, setEditSiteAdmin }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const siteData          = {};
  const [billingData, setbillingData] = useState({});
  const [shippingData, setshippingData] = useState({});
  const [coordinatorData, setCoordinatorData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const result = await CallGETAPI(
      "site/site-billing-shipping-data/" + siteId
    );
    if (result?.data?.status) {
      const resultData = result?.data?.data;
      setbillingData(resultData?.billingData);
      setshippingData(resultData?.shippingData);
      const CoordiData = GroupBYAdminSiteCoordinatorInfo(
        resultData?.contactInformation || []
      );
      setCoordinatorData(CoordiData);

      setEditSiteAdmin((prev) => ({
        ...prev,
        billingInfo: resultData?.billingData,
        shippingInfo: resultData?.shippingData,
      }));
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // fetch API Calling

  const hasPermisssion =
    Number(
      isPermission({
        type: "link",
        name: "contactName",
      })
    ) === 1;

  return (
    <>
      <table className="no-border">
        <tr className="bg-blue no-border">
          <th
            colSpan={2}
            className="bg-ghostwhite tbl-title text-left no-border"
            style={{ backgroundColor: "ghostwhite" }}
          >
            Billing Information
          </th>
          <th
            colSpan={2}
            className="tbl-title text-left no-border"
            style={{ backgroundColor: "ghostwhite" }}
          >
            Shipping Information
          </th>
        </tr>

        <tr>
          <th>Billing Contacts</th>
          <th>Billing Address</th>
          <th>Shipping Contacts</th>
          <th>Shipping Address</th>
        </tr>

        {/* {loading ? <SkeletonLoading/> : <> */}
        <tr>
          <td width={200}>
            <div>
              {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) : (
                <>
                  {coordinatorData &&
                    coordinatorData?.map((CoorD, i1) => {
                      if (CoorD.title === "Billing Coordinator") {
                        return (
                          <span key={i1}>
                            P:{" "}
                            <span
                              onClick={() => {
                                if (hasPermisssion) {
                                  navigate(
                                    "/account/" +
                                      accountId +
                                      "/contact-details/" +
                                      CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id,
                                    {
                                      state: {
                                        siteTitle:
                                          "Contact : " +
                                          CoorD?.arr?.[0]?.primary,
                                        // ||
                                        // CoorD?.arr?.[1]?.primary,
                                        editUrl:
                                          "/account/contact-details-edit/" +
                                            CoorD?.arr?.[0]?.contact_id ||
                                          CoorD?.arr?.[1]?.contact_id,
                                        deleteUrl:
                                          "/account/contact-details-edit/" +
                                            CoorD?.arr?.[0]?.contact_id ||
                                          CoorD?.arr?.[1]?.contact_id,
                                      },
                                    }
                                  );
                                }
                              }}
                              className={hasPermisssion && "link"}
                            >
                              {/* {" "}
                          {CoorD?.arr?.[0]?.primary || CoorD?.arr?.[1]?.primary} */}{" "}
                              {truncateText(
                                CoorD?.arr?.[0]?.primary ||
                                  CoorD?.arr?.[1]?.primary ||
                                  "",
                                15 // Maximum length before truncation
                              )}
                            </span>{" "}
                          </span>
                        );
                      } else {
                        // If title doesn't match "Shipping Contact", return null
                        return null;
                      }
                    })}
                </>
              )}
            </div>
            <div>
              {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) : (
                <>
                  {coordinatorData &&
                    coordinatorData?.map((CoorD, i1) => {
                      if (CoorD.title === "Billing Coordinator") {
                        return (
                          <span key={i1}>
                            B:{" "}
                            <span
                              onClick={() => {
                                if (hasPermisssion) {
                                  navigate(
                                    "/account/" +
                                      accountId +
                                      "/contact-details/" +
                                      CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id,
                                    {
                                      state: {
                                        siteTitle:
                                          "Contact : " +
                                          // CoorD?.arr?.[0]?.backup ||
                                          CoorD?.arr?.[1]?.backup,
                                        editUrl:
                                          "/account/contact-details-edit/" +
                                            CoorD?.arr?.[0]?.contact_id ||
                                          CoorD?.arr?.[1]?.contact_id,
                                        deleteUrl:
                                          "/account/contact-details-edit/" +
                                            CoorD?.arr?.[0]?.contact_id ||
                                          CoorD?.arr?.[1]?.contact_id,
                                      },
                                    }
                                  );
                                }
                              }}
                              className={hasPermisssion && "link"}
                            >
                              {/* {" "}
                              {CoorD?.arr?.[0]?.backup ||
                                CoorD?.arr?.[1]?.backup} */}{" "}
                              {truncateText(
                                CoorD?.arr?.[0]?.backup ||
                                  CoorD?.arr?.[1]?.backup ||
                                  "",
                                15 // Maximum length before truncation
                              )}
                            </span>{" "}
                          </span>
                        );
                      } else {
                        // If title doesn't match "Shipping Contact", return null
                        return null;
                      }
                    })}
                </>
              )}
            </div>
          </td>

          <td>
            {loading ? (
              <>
                <Skeleton
                  count={5}
                  width={"90%"}
                  height={20}
                  style={{ marginLeft: "2px", marginRight: "2px" }}
                />
              </>
            ) : (
              <>
                {billingData?.account_billing_info_address1 ? `${billingData?.account_billing_info_address1}, ` : ""}
                {billingData?.account_billing_info_address2 ? `${billingData?.account_billing_info_address2}, ` : ""}
                {billingData?.account_billing_info_city ? `${billingData?.account_billing_info_city}, ` : ""}
                {`${billingData?.account_billing_info_state ?? ""} ${billingData?.account_billing_info_zipcode ?? ""}, `}
                {billingData?.account_billing_info_country ?? ""}
                {/* {billingData?.account_billing_info_address1}{" "}
                {billingData?.account_billing_info_address2}{" "}
                {billingData?.account_billing_info_city}{" "}
                {billingData?.account_billing_info_state}{" "}
                {billingData?.account_billing_info_country}{" "}
                {billingData?.account_billing_info_zipcode} */}
              </>
            )}
          </td>

          <td width={200}>
            <div>
              {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) : (
                <>
                  {coordinatorData &&
                    coordinatorData?.map((CoorD, i1) => {
                      if (CoorD.title === "Shipping Contact") {
                        return (
                          <span key={i1}>
                            P:{" "}
                            <span
                              onClick={() => {
                                if (hasPermisssion) {
                                  navigate(
                                    "/account/" +
                                      accountId +
                                      "/contact-details/" +
                                      CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id,
                                    {
                                      state: {
                                        siteTitle:
                                          "Contact : " +
                                            CoorD?.arr?.[0]?.primary ||
                                          CoorD?.arr?.[1]?.primary,
                                        editUrl:
                                          "/account/contact-details-edit/" +
                                            CoorD?.arr?.[0]?.contact_id ||
                                          CoorD?.arr?.[1]?.contact_id,
                                        deleteUrl:
                                          "/account/contact-details-edit/" +
                                            CoorD?.arr?.[0]?.contact_id ||
                                          CoorD?.arr?.[1]?.contact_id,
                                      },
                                    }
                                  );
                                }
                              }}
                              className={hasPermisssion && "link"}
                            >
                              {/* {" "}
                              {CoorD?.arr?.[0]?.primary ||
                                CoorD?.arr?.[1]?.primary} */}{" "}
                              {truncateText(
                                CoorD?.arr?.[0]?.primary ||
                                  CoorD?.arr?.[1]?.primary ||
                                  "",
                                15 // Maximum length before truncation
                              )}
                            </span>{" "}
                          </span>
                        );
                      } else {
                        // If title doesn't match "Shipping Contact", return null
                        return null;
                      }
                    })}
                </>
              )}
            </div>
            <div>
              {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) : (
                <>
                  {coordinatorData &&
                    coordinatorData?.map((CoorD, i1) => {
                      if (CoorD.title === "Shipping Contact") {
                        return (
                          <span key={i1}>
                            B:{" "}
                            <span
                              onClick={() => {
                                if (hasPermisssion) {
                                  navigate(
                                    "/account/" +
                                      accountId +
                                      "/contact-details/" +
                                      CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id,
                                    {
                                      state: {
                                        siteTitle:
                                          "Contact : " +
                                            CoorD?.arr?.[0]?.backup ||
                                          CoorD?.arr?.[1]?.backup,
                                        editUrl:
                                          "/account/contact-details-edit/" +
                                            CoorD?.arr?.[0]?.contact_id ||
                                          CoorD?.arr?.[1]?.contact_id,
                                        deleteUrl:
                                          "/account/contact-details-edit/" +
                                            CoorD?.arr?.[0]?.contact_id ||
                                          CoorD?.arr?.[1]?.contact_id,
                                      },
                                    }
                                  );
                                }
                              }}
                              className={hasPermisssion && "link"}
                            >
                              {/* {" "}
                              {CoorD?.arr?.[0]?.backup ||
                                CoorD?.arr?.[1]?.backup} */}{" "}
                              {truncateText(
                                CoorD?.arr?.[0]?.backup ||
                                  CoorD?.arr?.[1]?.backup ||
                                  "",
                                15 // Maximum length before truncation
                              )}
                            </span>{" "}
                          </span>
                        );
                      } else {
                        // If title doesn't match "Shipping Contact", return null
                        return null;
                      }
                    })}
                </>
              )}
            </div>
          </td>

          <td>
            {loading ? (
              <>
                <Skeleton
                  count={5}
                  width={"90%"}
                  height={20}
                  style={{ marginLeft: "2px", marginRight: "2px" }}
                />
              </>
            ) : (
              <>
                {shippingData?.account_shipping_info_address1 ? `${shippingData?.account_shipping_info_address1}, ` : ""}
                {shippingData?.account_shipping_info_address2 ? `${shippingData?.account_shipping_info_address2}, ` : ""}
                {shippingData?.account_shipping_info_city ? `${shippingData?.account_shipping_info_city}, ` : ""}
                {`${shippingData?.account_shipping_info_state ?? ""} ${shippingData?.account_shipping_info_zipcode ?? ""}, `}
                {shippingData?.account_shipping_info_country ?? ""}
                {/* {shippingData?.account_shipping_info_address1}{" "}
                {shippingData?.account_shipping_info_address2}{" "}
                {shippingData?.account_shipping_info_city}{" "}
                {shippingData?.account_shipping_info_state}{" "}
                {shippingData?.account_shipping_info_country}{" "}
                {shippingData?.account_shipping_info_zipcode} */}
              </>
            )}
          </td>
        </tr>
      </table>
    </>
  );
};

export default BillingShipping;
