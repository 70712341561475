import React from "react";
import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { AccountDetailsTab } from "../../../common/hooks/index";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BASE_API,
  CallGETAPI,
  CallPOSTAPI,
} from "../../../common/services/index";
import { FormatDate, getPermission } from "../../../common/helper/Common";
import {
  AccountContactDetails,
  AccountSiteList,
  DecryptToken,
  fetchAedLength,
  getPer,
  GroupBYCoordinatorInfo,
  truncateText,
} from "../../../common/helper/BasicFn";
import Inperson from "../components/tabs/Inperson";
import Pops from "../components/tabs/Pops";
// import Notes from "../components/tabs/Notes";
import Emails from "../components/tabs/Emails";
// import Support from "../components/tabs/Support";
// import Documents from "../components/tabs/Documents";
import RFI from "../components/tabs/RFI";
import { styled } from "@mui/material/styles";
import { createTheme } from "@mui/material";
import TraningDetailsTabel from "../components/adminTrainingTable/TraningDetailsTabel";
import SubHeading from "../components/header/SubHeading";
import TableSkeleton from "../components/skeleton/table/TableSkeleton";
import Details from "../components/tabs/Details";
import Sites from "../components/tabs/Sites";
import Contacts from "../components/tabs/Contacts";
import Aeds from "../components/tabs/Aeds";
import { useDispatch, useSelector } from "react-redux";
import {
  handleTabfunction,
  selecteTab,
  setEditAccountData,
} from "../../../store/slices/TabSlice";
import { addItem } from "../components/header/BreadCrumbsSlice";
import "../../../global.css";
import { toast } from "react-toastify";
import {
  addNewBreadcrumbs,
  handleBreadcrumbsClick,
} from "../../../store/slices/breadcrumbsSlice";
import {
  isSubAdminPermission,
  isUserPermission,
} from "../../../common/helper/permission";
import SupportSymbol from "../../Support/Component/SupportSymbol";
import Support from "../../Support/Component/SupportTab/Support";
import { isPermission } from "../../../common/helper/PermissionManager";
import Notes from "../../Notes/Component/NoteTab/Notes";
import Documents from "../../Documnents/Pages/Documents";
// import Documents from "../../Documnents/Documents";
const drawerWidth = 200;
const theme = createTheme();
const AccountCache = new Map();

const AccountDetails = ({ setShowSidebar, handleSetToken }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const [showLoading, setShowLoading] = React.useState(false);
  const [outsideClick, setOutsideClick] = React.useState("");
  const { tab } = useParams();
  const currentTab = tab || "Details";
  // const [currentTab, setCurrentTab] = useState(!location?.state?.tab ? AccountDetailsTab.DETAILS : location?.state?.tab);
  const [accountDetails, setAccountDetails] = useState({});
  const [CoordiDataList, setCoordiDataList] = useState([]);
  const [programDetails, setProgramDetails] = useState({});
  const [accountInfoDetails, setAccountInfoDetails] = useState();
  const [NotesData, setNotesData] = useState([]);
  const [forward, setForward] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [httpsWeb, setHttpsWeb] = useState(false);
  const [FormMsg, setFormMsg] = React.useState({ type: true, msg: "" });
  const [programData, setProgramData] = useState({});
  const [aedUnitData, setAedUnitData] = useState([]);
  const [coordinatorData, setCoordinatorData] = useState([]);
  const [EditUrl, setEditUrl] = useState("/account/accounts-edit/" + accountId);
  // const [showAlert, setshowAlert] = useState("");
  const [aedLength, setAedLength] = useState();
  const user = DecryptToken();
  const privilege = getPermission();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  const fetchNotesData = async () => {
    // const response = await fetch('http://localhost:5000/api/notes');
    // const data = await response.json();
    // setNotesData(data);
    let response = await CallGETAPI("notes/account-notes/" + accountId);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const breadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.breadcrumbs
  );

  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "account",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
  };

  const handleDispatch = (accountId, item) => {
    // BreadCrumbsObject.tab = item;
    // BreadCrumbsObject.type = 'account';

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account-details/${accountId}/${item}`,
      })
    );
  };
  const handleTab = (item) => {
    if (item === "Details") {
      const accountName = accountDetails.account_name;
      dispatch(
        handleBreadcrumbsClick({
          accountName,
          breadcrumbsFromStore,
          tab: "Details",
          type: "account",
        })
      );
      navigate(`/account-details/${accountId}/${item}`);
    } else {
      BreadCrumbsObject.title = item;
      // BreadCrumbsObject.tab = item;
      // BreadCrumbsObject.type = 'account';
      navigate(`/account-details/${accountId}/${item}`);
      handleDispatch(accountId, item);
    }
    // dispatch(handleTabfunction({ value: item, type: "account" }))
    // setCurrentTab(item);
  };

  const fetchOnload = async () => {
    //  Fetch From the Cache
    const fetchUrl = `account/account_info_detail/${accountId}`;
    const cacheKey = `${fetchUrl}`; //-${parentKey}-${pageNum}-${searchText}`;

    //   if (AccountCache.has(cacheKey)) {
    //     const cachedData = AccountCache.get(cacheKey);
    //     setAccountDetails(cachedData?.account_details || {});
    //     // setData(prevData => [...prevData, ...cachedData.data]);
    //     // setTotalCount(cachedData.totalCount);
    //     return;
    // }

    // AccountCache
    setShowLoading(true);
    const accountRes = await CallGETAPI(fetchUrl);
    AccountCache.set(cacheKey, {
      account_details: accountRes?.data?.data?.AccountDetails,
    });

    const accountData = accountRes?.data?.data?.AccountDetails;
    setAccountDetails(accountData);

    var pattern = new RegExp("^(https?|http)://");
    if (pattern.test(accountDetails?.website)) {
      setHttpsWeb(true);
    }

    setShowLoading(false);
  };
  // const prev_selected_tb = useSelector(
  //   (state) => state?.TAB_manager?.selectedTab
  // );
  const prev_selected_tb = useSelector(
    (state) => state?.TAB_manager?.active_tab_list?.account
  );
  useEffect(() => {
    if (prev_selected_tb) {
      // setCurrentTab(prev_selected_tb)
    }
  }, [prev_selected_tb]);

  function getDataByTitle(data, searchTitle, role = "") {
    // Find the object with matching title
    const foundItem = data.find((item) => item.title === searchTitle);

    // If item not found, return empty array/object
    if (!foundItem) return role ? null : [];

    // If no role specified, return entire array
    if (!role) return foundItem.arr;

    // If role is specified, find specific object
    if (role === "primary") {
      return foundItem.arr.find((item) => item.is_primary === 1) || null;
    } else if (role === "backup") {
      return foundItem.arr.find((item) => item.is_backup === 1) || null;
    }

    // If invalid role specified, return null
    return null;
  }

  const handleSetData = () => {
    let accountInfoData = {};
    if (accountDetails?.parent_account_id !== "") {
      accountInfoData.parentAccount = {
        account_id: accountDetails?.parent_account_id,
        account_main_contact_firstname: accountDetails?.parent_account,
      };
    }
    if (accountDetails?.distributor_account_id !== "") {
      accountInfoData.distributor = {
        dropdown_distributor_id: accountDetails?.distributor_account_id,
        distributor_name: accountDetails?.distributor_account,
      };
    }
    if (accountDetails?.customer_id !== "") {
      accountInfoData.customer = {
        dropdown_customer_type_id: accountDetails?.customer_id,
        customer_type_name: accountDetails?.customer_type,
      };
    }
    if (accountDetails?.industry_id !== "") {
      accountInfoData.industry = {
        dropdown_industry_id: accountDetails?.industry_id,
        dropdown_industry_name: accountDetails?.industry,
      };
    }
    if (accountDetails?.terms_id !== "") {
      accountInfoData.terms = {
        dropdown_terms_id: accountDetails?.terms_id,
        dropdown_terms_name: accountDetails?.terms,
      };
    }
    if (accountDetails?.lead_source_id !== "") {
      accountInfoData.leadSource = {
        dropdown_lead_source_id: accountDetails?.lead_source_id,
        dropdown_lead_source_name: accountDetails?.lead_source,
      };
    }
    if (accountDetails?.account_status_id !== "") {
      accountInfoData.accountStatus = {
        drop_account_status_id: accountDetails?.account_status_id,
        account_status: accountDetails?.account_status,
      };
    }
    accountInfoData.restData = {
      account_name: accountDetails?.account_name,
      website: accountDetails?.website,
      inportant_notes: accountDetails?.inportant_notes,
      restricted: accountDetails?.restricted,
      productInterest: accountDetails?.product_interest_id,
      expiration_alert_toggle: accountDetails?.expiration_alert_toggle,
    };
    // setAccountInfoDetails(accountInfoData);

    const accountPocPrimary = getDataByTitle(
      coordinatorData,
      "Account POC",
      "primary"
    );
    const accountPocBackup = getDataByTitle(
      coordinatorData,
      "Account POC",
      "backup"
    );
    const aedAuditorPrimary = getDataByTitle(
      coordinatorData,
      "AED Auditor",
      "primary"
    );
    const aedAuditorBackup = getDataByTitle(
      coordinatorData,
      "AED Auditor",
      "backup"
    );
    const pmPrimary = getDataByTitle(
      coordinatorData,
      "Project Managers",
      "primary"
    );
    const pmBackup = getDataByTitle(
      coordinatorData,
      "Project Managers",
      "backup"
    );
    const srPrimary = getDataByTitle(coordinatorData, "Sales Reps", "primary");
    const srBackup = getDataByTitle(coordinatorData, "Sales Reps", "backup");

    setAccountInfoDetails((prev) => ({
      ...prev,
      ...accountInfoData,
      programData,
      coordinatorData: {
        account_poc: {
          primary: {
            id: accountPocPrimary === "" ? "" : accountPocPrimary?.id,
            account_main_contact_id:
              accountPocPrimary === "" ? "" : accountPocPrimary?.contact_id,

            account_main_contact_firstname:
              accountPocPrimary === "" ? "" : accountPocPrimary?.primary,
          },
          backup: {
            id: accountPocBackup === "" ? "" : accountPocBackup?.id,
            account_main_contact_id:
              accountPocBackup === "" ? "" : accountPocBackup?.contact_id,
            account_main_contact_firstname:
              accountPocBackup === "" ? "" : accountPocBackup?.backup,
          },
        },
        aed_auditor: {
          primary: {
            id: aedAuditorPrimary === " " ? "" : aedAuditorPrimary?.id,
            account_main_contact_id:
              aedAuditorPrimary === "" ? "" : aedAuditorPrimary?.contact_id,
            account_main_contact_firstname:
              aedAuditorPrimary === "" ? "" : aedAuditorPrimary?.primary,
          },
          backup: {
            id: aedAuditorBackup === "" ? "" : aedAuditorBackup?.id,
            account_main_contact_id:
              aedAuditorBackup === "" ? "" : aedAuditorBackup?.contact_id,
            account_main_contact_firstname:
              aedAuditorBackup === "" ? "" : aedAuditorBackup?.backup,
          },
        },
        projectManager: {
          primary: {
            id: pmPrimary === "" ? "" : pmPrimary?.id,
            account_main_contact_id:
              pmPrimary === "" ? "" : pmPrimary?.contact_id,
            account_main_contact_firstname:
              pmPrimary === "" ? "" : pmPrimary?.primary,
          },
          backup: {
            id: pmBackup === "" ? "" : pmBackup?.id,
            account_main_contact_id:
              pmBackup === "" ? "" : pmBackup?.contact_id,
            account_main_contact_firstname:
              pmBackup === "" ? "" : pmBackup?.backup,
          },
        },
        salesReps: {
          primary: {
            id: srPrimary === "" ? "" : srPrimary?.id,
            account_main_contact_id:
              srPrimary === "" ? "" : srPrimary?.contact_id,
            account_main_contact_firstname:
              srPrimary === "" ? "" : srPrimary?.primary,
          },
          backup: {
            id: srBackup === "" ? "" : srBackup?.id,
            account_main_contact_id:
              srBackup === "" ? "" : srBackup?.contact_id,
            account_main_contact_firstname:
              srBackup === "" ? "" : srBackup?.backup,
          },
        },
      },
    }));
  };

  useEffect(() => {
    if (coordinatorData?.length > 0 && accountDetails) {
      handleSetData();
    }
  }, [coordinatorData, accountDetails]);

  useEffect(() => {
    if (prev_selected_tb && !currentTab) {
      // setCurrentTab(prev_selected_tb);
    } else if (location?.state?.tab) {
      // setCurrentTab(location?.state?.tab);
    }
  }, [location]);

  useEffect(() => {
    // if(currentTab === "Details"){
    fetchOnload();
    // }
  }, [currentTab, accountId]);

  const documentRedirect = () => {
    navigate("/account-document-upload", {
      state: {
        type: "account",
        accountId,
        siteId: "",
      },
    });
  };

  const [documentData, setDocumentData] = useState(null);

  const getDocumentsData = async () => {
    const response = await CallGETAPI("get-all-documents/", accountId);

    if (response?.status) {
      setDocumentData(response.data.data);
    }
  };
  const [aedList, setAedList] = useState([]);
  const fetchAEDData = async () => {
    // const result = await CallGETAPI('account/get-aed/' + accountId);
    const result = await CallGETAPI(
      "account/get-aed-with-standalon/" + accountId
    );
    // account/get-aed-with-standalon
    if (result?.data?.status) {
      const aeds = result?.data?.data;
      const resultArr = [];
      for (let a1 = 0; a1 < aeds.length; a1++) {
        const aed1 = aeds[a1];
        let obj = {
          site_name: aed1.site_name,
          site_id: "",
          data: [],
        };

        for (let a2 = 0; a2 < aed1.data.length; a2++) {
          const aeds2d = aed1.data[a2]?.aed_details;
          obj.site_id = aeds2d?.site_id;
          let obj2 = {
            aed_id: aeds2d?.aed_id,
            site_id: aeds2d?.site_id,
            serial_number: aeds2d?.serial_number,
            placement: aeds2d?.placement,
            brand_name: aed1.data[a2]?.aed_brand,
            battery_expiration: [],
            pads_expiration: [],
            last_check: FormatDate(aeds2d?.last_check),
            last_service: aeds2d?.last_service,
            rms_check: aeds2d?.rms_check,
            pediatric_key: aeds2d?.pediatric_key,
          };
          let bi1 = aeds2d?.battery_info
            ? JSON.parse(aeds2d?.battery_info)
            : [];
          let si1 = aeds2d?.storage_info
            ? JSON.parse(aeds2d?.storage_info)
            : [];
          let cpi1 = aeds2d?.charge_pak_info
            ? JSON.parse(aeds2d?.charge_pak_info)
            : []; // charge_pak_info
          let cpi1Arr = cpi1.length > 0 ? cpi1 : [];
          let cpi1BatteryDatesata = cpi1Arr.map(
            (cpi1Arritem) => cpi1Arritem.battery_expiration
          );

          let sbi1 = aeds2d?.spare_battery_info
            ? JSON.parse(aeds2d?.spare_battery_info)
            : []; //spare_battery_info
          let sbi1Arr = sbi1.length > 0 ? sbi1[0] : [];

          let sbi1_arr =
            sbi1Arr?.has_battery_spare && sbi1Arr?.has_battery_spare?.length > 0
              ? sbi1Arr?.has_battery_spare.map(
                  (item) => item.battery_expiration
                )
              : [];
          let sbi1_arr2 =
            sbi1Arr?.has_9v_spare && sbi1Arr?.has_9v_spare?.length > 0
              ? sbi1Arr?.has_9v_spare.map((item) => item.battery_expiration)
              : [];
          let sbi1_arr3 =
            sbi1Arr?.has_10pk_spare && sbi1Arr?.has_10pk_spare?.length > 0
              ? sbi1Arr?.has_10pk_spare.map((item) => item.battery_expiration)
              : [];
          let sbi1_arr4 =
            sbi1Arr?.has_installby_spare &&
            sbi1Arr?.has_installby_spare?.length > 0
              ? sbi1Arr?.has_installby_spare.map(
                  (item) => item.battery_expiration
                )
              : [];
          let sbi1_arr5 =
            sbi1Arr?.has_man_spare && sbi1Arr?.has_man_spare?.length > 0
              ? sbi1Arr?.has_man_spare.map((item) => item.battery_expiration)
              : [];

          let spare_obj = [
            {
              title: "spare_battery_info",
              data: sbi1_arr,
              img: "/Battery.png",
            },
            {
              title: "spare_battery_info",
              data: sbi1_arr2,
              img: "/spare-battery.png",
            },
            {
              title: "spare_battery_info",
              data: sbi1_arr3,
              img: "/Battery.png",
            },
            {
              title: "spare_battery_info",
              data: sbi1_arr4,
              img: "/Battery.png",
            },
            {
              title: "spare_battery_info",
              data: sbi1_arr5,
              img: "/Battery.png",
            },
            {
              title: "spare_battery_info",
              data: cpi1BatteryDatesata,
              img: "/Battery.png",
            },
          ];

          let ppi1 = aeds2d?.pediatric_pad_info
            ? JSON.parse(aeds2d?.pediatric_pad_info)
            : [];
          let sppi1 = aeds2d?.spare_padric_pad_info
            ? JSON.parse(aeds2d?.spare_padric_pad_info)
            : [];
          let api1 = aeds2d?.adult_pad_info
            ? JSON.parse(aeds2d?.adult_pad_info)
            : [];
          let sapi1 = aeds2d?.spare_adult_pad_info
            ? JSON.parse(aeds2d?.spare_adult_pad_info)
            : [];

          let ppd =
            ppi1 && ppi1?.length > 0
              ? ppi1.map(
                  (item) =>
                    item?.pediatric_pad_expiration &&
                    item?.pediatric_pad_expiration != "" &&
                    item?.pediatric_pad_expiration
                )
              : [];
          let spsd =
            sppi1 && sppi1?.length > 0
              ? sppi1.map(
                  (item) =>
                    item?.spare_pediatric_pad_expiration &&
                    item?.spare_pediatric_pad_expiration != "" &&
                    item?.spare_pediatric_pad_expiration
                )
              : [];
          let apid =
            api1 && api1?.length > 0
              ? api1.map(
                  (item) =>
                    item?.adult_pad_expiration &&
                    item?.adult_pad_expiration != "" &&
                    item?.adult_pad_expiration
                )
              : [];
          let spd =
            sapi1 && sapi1?.length > 0
              ? sapi1.map(
                  (item) =>
                    item?.spare_adult_pad_expiration &&
                    item?.spare_adult_pad_expiration != "" &&
                    item?.spare_adult_pad_expiration
                )
              : [];

          let batteryDateArr = [];
          for (let bi = 0; bi < bi1.length; bi++) {
            const hab_batery = bi1[bi]?.has_battery;
            const has_9v = bi1[bi]?.has_9v;

            const has_10pk = bi1[bi]?.has_10pk;
            const has_installby = bi1[bi]?.has_installby;
            const has_man = bi1[bi]?.has_man;

            let arr =
              hab_batery && hab_batery?.length > 0
                ? hab_batery.map((item) => item.battery_expiration)
                : [];
            let arr2 =
              has_9v && has_9v?.length > 0
                ? has_9v.map((item) => item.battery_expiration)
                : [];
            let arr3 =
              has_10pk && has_10pk?.length > 0
                ? has_10pk.map((item) => item.battery_expiration)
                : [];
            let arr4 =
              has_installby && has_installby?.length > 0
                ? has_installby.map((item) => item.battery_expiration)
                : [];
            let arr5 =
              has_man && has_man?.length > 0
                ? has_man.map((item) => item.battery_expiration)
                : [];
            let obj = [
              { title: "hab_batery", data: arr, img: "/Battery.png" },
              { title: "has_9v", data: arr2, img: "/spare-battery.png" },
              { title: "has_10pk", data: arr3, img: "/Battery.png" },
              { title: "has_installby", data: arr4, img: "/Battery.png" },
              { title: "has_man", data: arr5, img: "/Battery.png" },
            ];

            batteryDateArr = obj;
          }

          let si_obj = {
            title: "storage_info",
            data: [],
            img: "/Aed-Cabinet.png",
          };
          // if(obj2?.brand_name==='Defibtech Lifeline'){
          //      si_obj = { title: "storage_info", data: [], img: '/spare-battery.png' };
          // }

          for (let si = 0; si < si1.length; si++) {
            const sie = si1[si];
            si_obj.data.push(FormatDate(sie?.expiry_date));
          }
          batteryDateArr.push(si_obj);

          obj2.battery_expiration = [...batteryDateArr, ...spare_obj];
          let Chargepad1Arr = cpi1Arr.map(
            (cpi1Item) => cpi1Item.pad_1_expiration
          );
          let Chargepad2Arr = cpi1Arr.map(
            (cpi1Item) => cpi1Item.pad_2_expiration
          );

          let obj3 = [
            { title: "adult_pad_info", data: apid, img: "/people-Group.svg" },
            {
              title: "spare_adult_pad_info",
              data: spd,
              img: "/people-Group.svg",
            },
            {
              title: "pediatric_pad_info",
              data: ppd,
              img: "/child-Vector.png",
            },
            {
              title: "spare_padric_pad_info",
              data: spsd,
              img: "/child-Vector.png",
            },
            {
              title: "spare_padric_pad_info",
              data: Chargepad1Arr,
              img: "/people-Group.svg",
            },
            {
              title: "spare_padric_pad_info",
              data: Chargepad2Arr,
              img: "/people-Group.svg",
            },
          ];
          obj2.pads_expiration = obj3;
          obj.data.push(obj2);
        }
        resultArr.push(obj);
      }

      setAedList(resultArr);
    }
  };

  // useEffect(() => {
  //   handleSetToken();
  //   fetchOnload();
  //   getDocumentsData();
  //   fetchAEDData();
  // }, []);

  const breadcrumbs1 = useSelector((state) => state.BreadCrumbs.items);

  const changeState = async () => {
    setForward(true);
  };

  useEffect(() => {
    dispatch(
      addItem({ title: "Accounts", path: location?.pathname, tab: currentTab })
    );
  }, [currentTab]);

  const handleExportReport = async () => {
    let payload = {
      account_id: accountId,
      tab: currentTab,
    };
    const res = await CallPOSTAPI("admin/report-account", payload);
    const path = res?.data?.filePath;
    handleDownload(path);
  };

  const equipmentPayloadData = useSelector(
    (state) => state.accountdetailsequipmentfilter.equipmentPayloadData
  );

  const handleExportAed = async () => {
    let payload = {};
    if (equipmentPayloadData != "") {
      payload = {
        accesory_brand_model: equipmentPayloadData?.accesory_brand_model,
        accessory_type: equipmentPayloadData?.accessory_type,
        aed_brand_model: equipmentPayloadData?.aed_brand_model,
        aed_checker: equipmentPayloadData?.aed_checker,
        expirationrange: equipmentPayloadData?.expirationrange,
        from: equipmentPayloadData?.from,
        site_name: equipmentPayloadData?.site_name,
        state: equipmentPayloadData?.state,
        to: equipmentPayloadData?.to,
        account_id: accountId,
        tab: "aed",
      };
    } else {
      payload = {
        accesory_brand_model: "",
        accessory_type: "",
        aed_brand_model: "",
        aed_checker: "",
        expirationrange: "",
        from: "",
        site_name: "",
        state: "",
        to: "",
        account_id: accountId,
        tab: "aed",
      };
    }
    const res = await CallPOSTAPI("admin/report-account", payload);
    const path = res?.data?.filePath;
    handleDownload(path);
  };

  const handleExportAccessory = async () => {
    let payload = {};
    if (equipmentPayloadData != "") {
      payload = {
        accesory_brand_model: equipmentPayloadData?.accesory_brand_model,
        accessory_type: equipmentPayloadData?.accessory_type,
        aed_brand_model: equipmentPayloadData?.aed_brand_model,
        aed_checker: equipmentPayloadData?.aed_checker,
        expirationrange: equipmentPayloadData?.expirationrange,
        from: equipmentPayloadData?.from,
        site_name: equipmentPayloadData?.site_name,
        state: equipmentPayloadData?.state,
        to: equipmentPayloadData?.to,
        account_id: accountId,
        tab: "accessory",
      };
    } else {
      payload = {
        accesory_brand_model: "",
        accessory_type: "",
        aed_brand_model: "",
        aed_checker: "",
        expirationrange: "",
        from: "",
        site_name: "",
        state: "",
        to: "",
        account_id: accountId,
        tab: "accessory",
      };
    }
    const res = await CallPOSTAPI("admin/report-account", payload);
    const path = res?.data?.filePath;
    handleDownload(path);
  };

  const handleDownload = (path) => {
    if (path) {
      const fullURL = BASE_API + `/${path}`;
      // Construct a temporary anchor element
      const link = document.createElement("a");
      link.href = fullURL;
      link.download = "report.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("File download successfully");
    } else {
      toast.error("No file to download");
    }
  };

  const [tabTbldata, setTabTbldata] = useState({
    site: false,
    contact: false,
    equipment: {
      aed: false,
      accessory: false,
    },
    note: false,
    support: false,
  });

  const fetchAedLengthApi = async () => {
    const aedLength = await fetchAedLength(accountId);
    setAedLength(aedLength);
  };

  useEffect(() => {
    fetchAedLengthApi();
  }, []);

  useEffect(() => {
    if (accountInfoDetails && Object.keys(accountInfoDetails).length > 0) {
      dispatch(setEditAccountData(accountInfoDetails));
    }
  }, [accountInfoDetails]);

  return (
    <>
      <div
        className="mt-4 main-site"
        style={{ width: "100%" }}
        onClick={(e) => {
          setOutsideClick(e);
        }}
      >
        <SubHeading
          hideNew="tab"
          title={`Account : ${
            accountDetails && accountDetails.account_name
              ? accountDetails.account_name
              : ""
          } `}
          D_title={`${
            accountDetails && accountDetails.distributor_account
              ? `(${accountDetails.distributor_account})`
              : ""
          }`}
          newUrl="/new-account"
          subHeading={true}
          hideHierarchy={
            // accountDetails?.parent_account != "" &&
            // accountDetails?.parent_account != 0
            //   ? false
            //   : true
            true
          }
          assign_equipment={aedLength?.aedCount > 0 ? true : false}
          loading={showLoading}
          account_id={accountId}
          editUrl={"/account/accounts-edit/" + accountId + "/AccountEdit"}
          outsideClickEvent={outsideClick}
          accountName={"accountName"}
          support_type="Account"
          support_name={accountDetails?.account_name}
          site_id={0}
          exportReport={true}
          currentTab={currentTab}
          handleExportReport={handleExportReport}
          handleExportAed={handleExportAed}
          handleExportAccessory={handleExportAccessory}
          tabTbldata={tabTbldata}
          accountDetails={accountInfoDetails}
          programData={programData}
          aedUnitData={aedUnitData}
          coordinatorData={coordinatorData}
          CurrentTab={currentTab}
        />

        <Box>
          <div className="d-flex border-bottom border-secondary bg-primary">
            {Object.values(AccountDetailsTab).map((tabItem, i) => {
              if (
                Number(isPermission({ type: "account", name: tabItem })) ===
                  1 ||
                (tabItem === "Equipment" && user?.assign_aed)
              ) {
                return (
                  <div
                    role="button"
                    key={i}
                    className={"text-light tab-button"}
                    style={{
                      backgroundColor: `${
                        tabItem === currentTab ? "#26AEE0" : "#0C71C3"
                      }`,
                    }}
                    onClick={() => handleTab(tabItem)}
                  >
                    {tabItem}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </Box>

        {currentTab === AccountDetailsTab.DETAILS && (
          <>
            <Details
              accountDetails={accountDetails}
              CoordiDataList={CoordiDataList}
              programDetails={programDetails}
              httpsWeb={httpsWeb}
              tabTbldata={tabTbldata}
              setProgramData={setProgramData}
              setAedUnitData={setAedUnitData}
              setCoordinatorData={setCoordinatorData}
              showLoading={showLoading}
            />
          </>
        )}

        {currentTab === AccountDetailsTab.SITES && (
          <>
            <Sites
              setTabTbldata={setTabTbldata}
              accountName={accountDetails?.account_name}
            />
          </>
        )}

        {currentTab === AccountDetailsTab.CONTACTS && (
          <>
            <Contacts setTabTbldata={setTabTbldata} />
          </>
        )}

        {/* DOCUMENTS */}
        {currentTab === AccountDetailsTab.DOCUMENTS && (
          <Documents
            documentData={documentData}
            type="Account"
            document_api={`document/get-all-documents/${accountId}`}
            accountId={accountId}
            url={`/account-details/${accountId}/Documents`}
            stateData={{
              type: "account",
              site_id: 0,
              accountId: accountId ? accountId : 0,
              contactId: 0,
              accountName: accountDetails?.account_name || "",
              support_name: accountDetails?.account_name,
            }}
          />
        )}

        {/* POPS/PLANS */}
        {currentTab === AccountDetailsTab.INPERSON && <Inperson />}

        {/* POPS/PLANS */}
        {currentTab === AccountDetailsTab.POPS && (
          <Pops accountId={accountId} type="ACCOUNT" />
        )}

        {/* TRAINING */}
        {currentTab === AccountDetailsTab.TRAINING && <TraningDetailsTabel />}

        {/* SUPPORT */}

        {currentTab === AccountDetailsTab.SUPPORT && (
          <Support
            support_api={
              Number(getPer()) === 1
                ? "support/support-ticket-by-account/" + accountId
                : "user/support-list-for-account/"
            }
            newSupportUrl={`/account/new-support/?accountId=${accountId}&sectionName=account`}
            setTabTbldata={setTabTbldata}
            fetch_data_key={"ticketData"}
            stateData={{
              type: "account",
              site_id: 0,
              accountId: accountId ? accountId : 0,
              contactId: 0,
              accountName: accountDetails?.account_name || "",
              support_name: accountDetails?.account_name,
            }}
          />
        )}

        {/* Notes */}
        {currentTab === AccountDetailsTab.NOTES && (
          <Notes
            note_api={`notes/account-notes/${accountId}`}
            accountId={accountId}
            type="ACCOUNT"
            fetch_key={"accountNotes"}
          />
        )}

        {/* Emails */}
        {currentTab === AccountDetailsTab.EMAILS && (
          <Emails
            accountId={accountId}
            emailApi={`email/emails-by-account/${accountId}`}
          />
        )}

        {/* RFI */}
        {currentTab === AccountDetailsTab.RFI && <RFI />}

        {/* AEDS */}
        {currentTab === AccountDetailsTab.Equipment && (
          <Aeds
            tabTbldata={tabTbldata}
            setTabTbldata={setTabTbldata}
            accountName={accountDetails?.account_name}
            aedLength={aedLength}
          />
        )}
      </div>
      <div className="floating-menu-btn d-flex flex-column gap-2">
        <SupportSymbol
          note_api={`/account/new-note?account_id=${accountId}`}
          newSupportUrl={`/account/new-support/?accountId=${accountId}&sectionName=account`}
          document_url={`/account-details/${accountId}/Documents`}
          stateData={{
            type: "account",
            site_id: 0,
            accountId: accountId ? accountId : 0,
            contactId: 0,
            accountName: accountDetails?.account_name || "",
            support_name: accountDetails?.account_name,
          }}
        />
      </div>
    </>
  );
};

export default AccountDetails;
